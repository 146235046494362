<script setup lang="ts">
import algoliasearch from "algoliasearch"
import { ProductTabProps } from "../Rails/ProductTab.props"
import type {
  ComponentAlgoliaTabsCarouselProps,
  FragmentAlgoliaTabCarouselProps
} from "./ComponentAlgoliaTabsCarousel.props"
import { mapProducts } from "~/utils/mapProducts"
import { checkEnvSite } from "~/server/utils/getAlgoliaConfig"
import { buildExcludeFilter } from "~/utils/algolia"

const props = defineProps<ComponentAlgoliaTabsCarouselProps>()

const algoliaClient = algoliasearch(
  useRuntimeConfig().public.algolia.applicationId,
  useRuntimeConfig().public.algolia.apiKey
)

const lastViewedProducts: any = useStatefulCookie("lastProductsViewed", {
  default: () => []
})

const queries = computed(() => {
  return props.tabs?.map((tab) => {
    if (tab.facetAlgolia !== "lastProductsViewed") {
      return {
        indexName: `TOP_PRODUCTS${checkEnvSite}`,
        params: {
          facetFilters: `${tab.facetAlgolia}:${tab.valueAlgolia}`,
          hitsPerPage: 15,
          clickAnalytics: true,
          ...buildExcludeFilter({ excludeOutOfMarket: true })
        }
      }
    } else if (lastViewedProducts?.value?.length > 0) {
      const lastViewedProductsFilter = lastViewedProducts.value
        ?.map((productCode: string) => "product_" + productCode)
        .join(" OR ")
      return {
        indexName: `TOP_PRODUCTS${checkEnvSite}`,
        params: {
          facetFilters: lastViewedProductsFilter,
          hitsPerPage: lastViewedProducts.value.length,
          clickAnalytics: true,
          ...buildExcludeFilter({ excludeOutOfMarket: true })
        }
      }
    } else return
  })
})

const lastCarouselCardsArray = computed(() => {
  return props.tabs
    ? props.tabs.map((tab: FragmentAlgoliaTabCarouselProps) => {
        const lastCardInfo = tab.lastCarouselCard ?? null
        if (lastCardInfo) {
          return {
            ...lastCardInfo,
            cardType: "withCTA",
            cta: {
              ...lastCardInfo.cta,
              theme: "outlined-black"
            }
          }
        }
        return null
      })
    : []
})

const productTabs = ref<ProductTabProps>({
  tabs: {
    tabsList: []
  },
  typeCard: "Product"
})

await algoliaClient.multipleQueries(queries.value).then((response: any) => {
  productTabs.value.tabs.tabsList = props.tabs
    ? props.tabs.map((tab: FragmentAlgoliaTabCarouselProps, index) => {
        const p = response.results[index]
        // add a "preload: true" prop to the first 5 images of the first tab
        // only if the props tell us to preload the images
        if (index === 0 && props.preloadImages) {
          p.hits = p.hits.map((p2, i2) => ({
            ...p2,
            mainImage: {
              ...p2.mainImage,
              preload: i2 < 5,
              imgAttrs: { fetchpriority: i2 < 5 ? "high" : "auto" }
            }
          }))
        }
        const productTab = mapProducts(p.hits, p.queryID, "ALGOLIA")
        return {
          text: tab.label,
          content:
            productTab && lastCarouselCardsArray.value[index]
              ? [...productTab, lastCarouselCardsArray.value?.[index]]
              : productTab
        }
      })
    : []
})
</script>

<template>
  <div class="cms-component-algolia-tabs-carousel">
    <ClientOnly>
      <RailsProductTab
        v-if="productTabs?.tabs?.tabsList?.length"
        v-bind="productTabs"
      />
    </ClientOnly>
  </div>
</template>
